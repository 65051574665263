<template>
  <v-container fluid class="pa-0">
    <v-row class="pt-1">
      <v-col cols="12" v-if="loading">
        <div class="d-flex align-center justify-center qj-loading">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="70"
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <p class="ma-0 px-3">
          Caminho:
          <router-link :to="'/asset/' + snapshot.asset.id">{{
            snapshot.asset.path
          }}</router-link>
          &nbsp; Referência:
          <a
            :href="
              'https://backoffice.quiteja.com.br/?q=' +
              snapshot.external_ref
            "
          >
            {{ snapshot.external_ref }}
          </a>
          &nbsp; Criação:
          {{ snapshot.created_at + 'Z' | moment('L LTS') }}
        </p>
      </v-col>
      <v-col
        cols="12"
        v-if="!loading"
        class="py-0"
        style="height: calc(100vh - 104px); min-height: 400px"
      >
        <iframe
          frameborder="0"
          style="overflow: hidden; height: 100%; width: 100%"
          height="100%"
          width="100%"
          :src="snapshot.url"
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { bus } from "../../../main";
import snapshots from "@/services/snapshots";

export default {
  data: () => ({
    loading: false,
    local: "",
    snapshot: {},
  }),
  created() {
    this.local = window.origin;
    bus.$emit("title", "Snapshot");
    this.loading = true;
    snapshots
      .single(this.$route.params.id)
      .then((resp) => (this.snapshot = resp.data.data))
      .catch(() => this.$router.push({ name: "Home" }))
      .finally(() => (this.loading = false));
  },
};
</script>

<style scoped>
.qj-loading {
  width: 100%;
  height: calc(100vh - 168px);
}
</style>
